import React, { useState } from "react";
import { BeatLoader } from "react-spinners";

export default function WaitingListForm() {
  const [isLoading, setIsLoading] = useState(false);
  const handleSend = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // send email
    setIsLoading(false);
  };
  return (
    <form onSubmit={handleSend} className="waiting-list-form">
      <input
        type="email"
        placeholder="Your email address"
        className="waiting-list-input"
      />
      <button
        type="submit"
        className="butn green-butn round-butn waiting-list-button"
      >
        {!isLoading ? (
          "Join waiting list"
        ) : (
          <BeatLoader size={10} color="white" />
        )}
      </button>
    </form>
  );
}

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import WaitingListForm from "./WaitingListForm";

export default function CommingSoon() {
  let { bgImage, mockupImage } = useStaticQuery(
    graphql`
      query {
        bgImage: file(relativePath: { eq: "purple-gradient.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        mockupImage: file(relativePath: { eq: "phone-mockup.png" }) {
          childImageSharp {
            gatsbyImageData(width: 700, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );
  bgImage = getImage(bgImage);
  bgImage = convertToBgImage(bgImage);
  mockupImage = getImage(mockupImage);

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      preserveStackingContext
      style={{ backgroundPosition: "top", zIndex: 2, overflow: "hidden" }}
    >
      <div className="landing-coming-soon">
        <div className="left">
          <h2 className="heading" data-aos="fade-left">
            We want to make the best product for YOU
          </h2>
          <p className="description" data-aos="fade-left">
            We actively seek and listen to feedback, and use it to continuously
            improve our online ticketing app. Your suggestions and ideas are
            important to us, and we're committed to delivering the best possible
            product to meet your needs.
          </p>
          <div data-aos="fade-left">
            <WaitingListForm />
          </div>
        </div>
        <div className="right" data-aos="fade-right">
          <GatsbyImage
            image={mockupImage}
            alt="Rian2d"
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </BackgroundImage>
  );
}

import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";
import AOS from "aos";

export default function DefaultPageLayout({
  children,
  title,
  description,
  keywords,
  robots,
  canonical,
}) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title ||
            "Ticketl.ie - Cheap and Simple Ticketing for Events and Conferences"}
        </title>
        <meta
          name="description"
          content={
            description ||
            "Ticketl.ie offers cheap and simple ticketing for events and conferences. Get as many tickets as you like for one affordable fee. Our platform makes it easy for organizers to manage ticket sales and attendees."
          }
        />
        <meta
          name="keywords"
          content={
            keywords ||
            "ticketing, events, conferences, affordable, cheap, simple, organizer, manage, attendees"
          }
        />
        <meta name="robots" content={robots || "index, follow"} />
        <link rel="canonical" href={canonical || "https://ticketl.ie/"} />
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
}

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Description() {
  const data = useStaticQuery(graphql`
    query {
      myImage: file(relativePath: { eq: "rian2d.png" }) {
        childImageSharp {
          gatsbyImageData(width: 520, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `);
  const image = data.myImage.childImageSharp.gatsbyImageData;

  return (
    <div className="landing-page-description">
      <div className="left">
        <h2 className="heading" data-aos="fade-right">
          What is Ticketl.ie?
        </h2>
        <p className="description" data-aos="fade-right">
          Whether you're a busy professional, a student, or just someone who
          wants to stay on top of their to-do list, Taskl.ie can help. Our AI
          technology uses machine learning algorithms to intelligently
          categorize your tasks and suggest the best ways to prioritize and
          organize them.
        </p>
        <button
          className="butn orange-butn large-butn round-butn"
          data-aos="fade-right"
        >
          Browse Features
        </button>
      </div>
      <div className="right">
        <GatsbyImage image={image} alt="Rian2d" />
      </div>
    </div>
  );
}

import React from "react";
import HeroImage from "./HeroImage";
import WaitingListForm from "./WaitingListForm";

export default function Hero() {
  return (
    <HeroImage>
      <div className="hero-container">
        <div className="hero-content">
          <h1 className="hero-heading" data-aos="fade-in">
            The safest and cheapest way to buy and sell tickets
          </h1>
          <div data-aos="fade-in">
            <WaitingListForm />
          </div>
        </div>
      </div>
    </HeroImage>
  );
}

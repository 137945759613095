import { Link } from "gatsby";
import React from "react";

export default function Header() {
  return (
    <header className="header">
      <div className="header-left">
        {/* replace this with Ticketl.ie logo once we have it */}
        <h1 className="nunito-semibold text-white text-3xl">Ticketl.ie</h1>
      </div>
      <div className="header-right">
        <div className="links-container">
          <Link to="/about" className="link">
            About us
          </Link>
          <Link to="/features" className="link">
            Features
          </Link>
          <Link
            to="https://app.ticketl.ie"
            className="butn orange-butn round-butn external-link"
          >
            SIGN IN
          </Link>
        </div>
      </div>
    </header>
  );
}

import React from "react";
import DefaultPageLayout from "../components/layout/DefaultPageLayout";
import Hero from "../components/index/Hero";
import Description from "../components/index/Description";
import ComingSoon from "../components/index/ComingSoon";

const IndexPage = () => {
  
  return (
    <DefaultPageLayout
      title="Ticketl.ie - Cheap and Simple Ticketing for Events and Conferences"
      description="Ticketl.ie offers cheap and simple ticketing for events and conferences. Get as many tickets as you like for one affordable fee. Our platform makes it easy for organizers to manage ticket sales and attendees."
      keywords="ticketing, events, conferences, affordable, cheap, simple, organizer, manage, attendees"
      canonical="https://ticketl.ie/"
    >
      <Hero />
      <Description />
      <ComingSoon />
    </DefaultPageLayout>
  );
};

export default IndexPage;
